<template>
  <div>
    <contract-detail>
      <!-- <template v-slot:alo>Xin chào</template> -->
      <!-- Title -->
      <template v-slot:title>{{ $route.params.type }}契約</template>
      <!-- End Title -->

      <template v-slot:cancel>
        <v-btn
            style="width: 100px"
            class="t-btn--red-dark mr-4 btn-custom"
            @click="closeDialog"
          >
            <v-icon class="white--text"> mdi-close </v-icon>
            閉じる
          </v-btn>
      </template>
      <template v-slot:send>
        <v-btn
            style="width: 68px"
            class="t-btn--prm btn-custom"
            :disabled="!checkUser"
            @click="save(0)"
          >
            保存
          </v-btn>
      </template>
      
      <!-- Content  -->
      <template v-slot:default="{isEffective}">
        <div class="mx-10 mt-2">
          <div>
            <v-btn class="t-btn--prm" @click="changePagePMS(toID)" :disabled="!checkUser || !isEffective">
              予約作成
            </v-btn>
          </div>
          <div class="d-flex justify-start">
            <div>
              <p class="text">契約継続年数</p>
              <p class="text">
                {{ contractDuration.year }}年{{ contractDuration.month }}ヶ月
              </p>
            </div>
            <div>
              <p class="text">年会費支払い月</p>
              <p class="text">{{ annualFeePaymentMonth }}月</p>
            </div>
            <div>
              <p class="text">自動更新フラグ</p>
              <p class="text">{{ autoRenewal ? 'オン' : 'オフ'}}</p>
            </div>
          </div>
          <div class="d-flex justify-start">
            <v-btn
              :disabled="autoRenewal === null || autoRenewal === false || !checkUser || !isEffective"
              @click="dialogOpen = true"
              class="btn-crm-red mr-5"
            >
              自動更新フラグをOFFにする
            </v-btn>
            <v-btn
              class="btn-crm-red"
              @click="dialogOpen = true"
              :disabled="autoRenewal === true || !checkUser || !isEffective"
            >
              自動更新フラグをONにする
            </v-btn>
          </div>
        </div>
      </template>
      <!-- End Content  -->
    </contract-detail>

    <!-- Dialog -->
    <v-dialog @click:outside="closeDialog" v-model="dialog">
      <h1 style="color: #212121">本当に自動更新フラグを変更しますか？</h1>
      <div class="d-flex justify-end mt-2">
        <v-btn
          style="font-size:14px"
          class="btn-crm-red btn-dialog mr-2"
          width="200"
          @click="save(1)"
          small
        >
          {{
            autoRenewal
              ? '自動更新フラグをOFFにする'
              : '自動更新フラグをONにする'
          }}
        </v-btn>
        <v-btn
          style="font-size:14px"
          class="t-btn--prm btn-dialog mr-2"
          width="68"
          small
          @click="closeDialog"
        >
          戻る
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import contractDetail from '../ContractDetail.vue';
import gql from 'graphql-tag';
import moment from 'moment';
import { mapMutations } from 'vuex';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
// import { LINK_PMS } from '@/constants/linkPMS.js';

export default {
  name: 'usageStatusVS',
  data() {
    return {
      toID: this.$route.params.id,
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      contractDuration: {},
      annualFeePaymentMonth: null,
      autoRenewal: true,
      dialogOpen: false,
    };
  },
  mounted() {
    this.getInfoContract();
  },
  computed: {
    dialog: {
      get() {
        return this.dialogOpen;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations(['setCopyErrorText', 'setAlertSuccess','setAlertError', 'setPermissionUser', 'setRoleAdminUser']),
    async getInfoContract(item) {
      this.$store.commit('setLoadingOverlayShow', { root: true })
      const query = `
            query($contractId: Int!) {
              contract(id: $contractId) {
                startDate #DateString ここの日付から今日までで、契約継続年数を表示する
                # startDateの月 new Date(startDate).getMonth() + 1 が年会費支払月
                autoRenewal #Boolean, true:オン, false|null:オフ
              }
            }
          `
      const variables = {
            contractId: parseInt(this.$route.params.id),
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          const contract = data.data.contract;
          if (contract.startDate) {
            const __startTime = moment(contract.startDate);
            const __endTime = moment(new Date());
            const __duration = moment.duration(
              moment(__endTime).diff(__startTime),
            );
            this.contractDuration.year = __duration._data.years;
            this.contractDuration.month = __duration._data.months;
            this.annualFeePaymentMonth =
              new Date(moment(contract.startDate)).getMonth() + 1;
          }
          if (contract.autoRenewal) {
            this.autoRenewal = contract.autoRenewal;
          } else {
            this.autoRenewal = null;
          }
          this.$store.commit('setLoadingOverlayHide', { root: true })
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
          this.$store.commit('setLoadingOverlayHide', { root: true })
        });
      if (this.flag) return true;
      else return false;
    },

    closeDialog() {
      window.close()
      this.dialogOpen = false;
      this.$emit('close');
    },

    async save(idx) {
      const query = `
            mutation($contractId: Int!, $autoRenewal: Boolean!) {
              updateContract(
                contractId: $contractId
                data: { autoRenewal: $autoRenewal }
              ) {
                __typename
              }
            }
          `
      const variables = {
            contractId: parseInt(this.$route.params.id),
            autoRenewal: idx === 0 ? this.autoRenewal : !this.autoRenewal,
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          this.autoRenewal = idx === 0 ? this.autoRenewal : !this.autoRenewal
          if(idx === 0 ){
            this.setAlertSuccess('保存しました');
          }
          else{
            this.setAlertSuccess('作成しました。');
          }
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        });
    },
    changePagePMS(toID){
      const link = `${process.env.VUE_APP_URL_PMS}/reservation/calendar?id=${this.$route.query.clientId}&contractId=${this.$route.query.contractId}&fromPage=crm`
      window.open(link)
    }
  },
  components: {
    contractDetail,
  },
};
</script>

<style lang="scss" scoped>
.text {
  color: #424242;
  font-size: 16px;
  margin-top: 15px;
  margin-right: 20px;
  font-weight: 400;
}

::v-deep {
  .v-dialog {
    width: 550px;
    height: 120px;
    background-color: white;
    padding: 15px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .btn-crm-red {
    color: #ffffff !important;
    background-color: #db3069 !important;
  }
  .v-btn--disabled {
    background-color: #bdbdbd !important;
    .v-btn__content{
      color: #ffffff !important;
    }
    border: 1px solid #bdbdbd !important;
  }
}
</style>
